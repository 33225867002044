import Corn from "./media/Corn.jpeg";
import DriedGinger from "./media/DriedGinger.jpeg";
import Rice from "./media/rice.jpeg";
import SesemeSeeds from "./media/SesemeSeeds.jpeg";
import FreshGinger from "./media/FreshGinger.jpeg";
import CowPea from "./media/Cowpea.jpeg";
const products = [
  {
    title: "Dry Ginger",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: DriedGinger,
  },
  {
    title: "Fresh Ginger",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: FreshGinger,
  },
  {
    title: "Cowpea",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: CowPea,
  },
  {
    title: "Sesame Seeds",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: SesemeSeeds,
  },
  {
    title: "Rice",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: Rice,
  },
  {
    title: "Corn",
    description: "",
    buttonText: "Learn More",
    link: "#",
    image: Corn,
  },
];

export default products;
