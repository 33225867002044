import CornField from "./media/corn_field2.jpeg";
import CowPea from "./media/cowpea1.jpg";
import Tractor from "./media/tractor2.jpeg";
import Livestock from "./media/livestock.jpeg";
import Ginger from "./media/ginger.jpeg";
import Machine from "./media/machine.jpg";
const products = [
  {
    title: "Mechanized Farming and Agro-allied Products",
    description:
      "We offer mechanized farming solutions and a range of agro-allied products to help you enhance your farming operations.",
    buttonText: "Learn More",
    link: "#",
    image: Machine,
  },
  {
    title: "Poultry, Fishery, and Livestock Feeds",
    description:
      "We produce high-quality feeds for poultry, fishery, and livestock to improve the health and growth of your animals.",
    buttonText: "Learn More",
    link: "#",
    image: Livestock,
  },
  {
    title: "Processing and Supply of Foods and Food Products",
    description:
      "We provide food processing and supply services to ensure that you receive fresh, high-quality food products.",
    buttonText: "Learn More",
    link: "#",
    image: Ginger,
  },
  {
    title: "General Service to Farmers",
    description:
      "We offer general farming services, such as land preparation, planting, harvesting, and more, to help you achieve your farming goals.",
    buttonText: "Learn More",
    link: "#",
    image: CornField,
  },
  {
    title: "Import and Export of Agricultural Implements",
    description:
      "We import and export a range of agricultural implements, such as tractors, plows, cultivators, and more, to help you improve your farming operations.",
    buttonText: "Learn More",
    link: "#",
    image: Tractor,
  },
  {
    title: "Marketing, Sales, and Distribution of Agric Goods",
    description:
      "We provide marketing, sales, and distribution services for agricultural goods, such as crops, livestock, and food products, to help you reach a wider market and grow your business.",
    buttonText: "Learn More",
    link: "#",
    image: CowPea,
  },
];

export default products;
