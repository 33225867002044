import React, { useRef } from "react";
import { FaPhoneAlt, FaSearchLocation } from "react-icons/fa";
import {
  AiTwotoneMail,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";
import emailjs from '@emailjs/browser';
import "./styles/contact.css";

export default function ContactPage() : JSX.Element{
  const form = useRef();
  
  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs.sendForm('service_5ql53fp', 'template_g16cuub', form.current, 'biSlead5FEcpbTN4B')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <div data-testid="contact-page" className="contact-page">
      <div className="card">
        <div className="card-header">
          <h2>Contact Us</h2>
        </div>
        <div className="card-body">
          <div className="form-container">
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                required
              />

              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
              />

              <textarea
                id="message"
                name="message"
                rows={10}
                placeholder="How can we assist you"
                required
              ></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="contact-info">
            <h3 className="contact-info-title">Contact Info</h3>
            <FaSearchLocation className="icon" />
            <p>Suite 411, The Kings Plaza. </p>
            <p>Plot 307, Ahmadu Bello Way, Kado. </p>
            <p>Abuja, Nigeria.</p>
            <FaPhoneAlt className="icon" />
            <p> +234 803 705 1388</p>
            <p> +234 812 828 6155</p>
            <AiTwotoneMail className="icon" />
            <p>veezeefarms@gmail.com</p>

            <div className="social-media-links">
              <h3>Follow Us</h3>
              <AiFillFacebook className="icon" />
              <AiFillTwitterSquare className="icon" />
              <AiFillInstagram className="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
