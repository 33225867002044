import "./App.css";
import Footer from "./components/footer";
import Home from "./components/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/navigation";
import Contact from "./components/contact";
import About from "./components/about";
import React from "react";
import Products from "./components/products";

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/about-the-farm" element={<About />} />
            <Route path="/products" element={<Products />} /> 
          <Route path="/contacts" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
