import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import cowpea from "../media/cowpea1.jpg";
import corn from "../media/corn_field.jpeg"
import "./styles/silder.css";

const images = [cowpea, corn];

function Slider() : JSX.Element{
  return (
    <div className="slideshow-container" data-testid="slider">
      <Slide>
        {images.map((image, index) => (
          <div key={index} className="each-slide" data-testid="slide">
            <div style={{ backgroundImage: `url(${image})` }}></div>
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default Slider;
