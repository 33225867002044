import React from "react";
import Product from "./product";
import products from "../products.arrays";
import "./styles/home.css";
import Silder from "./slider";
import { useNavigate } from "react-router-dom";

const pageTitle: string = "Welcome To VeeZeePee Farms";
const pageDescription: string = `VeeZeePee Farms Limited is committed to supporting both small- and large-scale farmers by offering a diverse range of agricultural services. 
Our expertise in tree crop production, ginger farming, arable farming, animal husbandry, and livestock breeding allows us to provide tailored solutions to meet the needs of every farmer. 
We also offer mixed farming, contract farming, and provide comprehensive marketing and distribution services to help our clients reach wider markets. With our focus on sustainable farming 
practices and the use of modern technologies, we strive to promote the growth of the agricultural industry.`;

export default function Home() {
  const navigate = useNavigate();


  const handleProductClick = () => {
    navigate("/products");
  };

  return (
    <div data-testid="home" className="home">
      <Silder />
      <h1 className="home-title">{pageTitle}</h1>
      <p className="description">{pageDescription}</p>

      <h2 className="home-sub-title">Our Products and Services</h2>
      <div className="products">
        {products.map((product) => (
          <Product
            key={product.title}
            title={product.title}
            description={product.description}
            image={product.image}
            onClick={handleProductClick}
          />
        ))}
      </div>
    </div>
  );
}
