import React from "react";
import "./styles/footer.css";
import Logo from "../media/vzp-logo.png";
import { FaPhoneAlt, FaSearchLocation } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { BiRightArrow } from "react-icons/bi";

const today: Date = new Date();
const year: number = today.getFullYear();

export default function Footer() : JSX.Element {
  return (
    <footer data-testid="footer">
      <div className="container">
        <div className="footer-row">
          <div>
            <div className="address">
              <a href="index.html">
                {" "}
                <img className="footer-logo" src={Logo} alt="logo" />
              </a>
              <p className="summary">
                VeeZeePee Farms provides agricultural services, expertise, and
                products to support farmers of all sizes.They also buy, sell,
                import, and export produce and livestock, and aim to provide
                personalized and customer-friendly service.{" "}
              </p>
            </div>
          </div>
          <div>
            <div className="address">
              <h3 className="mobile-font links-heading">Quick links</h3>
              <ul className="links_footer">
                <li>
                  <BiRightArrow className="footer-icon" />
                  <a href="/"> Home Page</a>{" "}
                </li>
                <li>
                  <BiRightArrow className="footer-icon" />
                  <a href="/products"> Our Products </a>{" "}
                </li>
                <li>
                  <BiRightArrow className="footer-icon" />
                  <a href="/contacts">Get In Touch</a>{" "}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="address">
              <h3 className="mobile-font extra-top-margin">Subcribe email</h3>
              <p className="mailing-list">
                Subscribe to our mailing list to stay updated with the latest
                and freshest products straight from our farm to your doorstep.{" "}
              </p>
              <input
                className="form-control"
                placeholder="Your Email"
                type="type"
                name="Your Email"
              />
              <button className="submit-btn">Submit</button>
            </div>
          </div>

          <div>
            <div className="address">
              <h3 className="mobile-font extra-top-margin">Contact Us</h3>

              <ul className="mobile-font footer-list">
                <li className="list">
                  <span >
                    <FaSearchLocation className="footer-icon" />
                  </span>
                  Kado, Abuja, Nigeria.{" "}
                </li>
                <li className="list">
                  <span>
                    <AiTwotoneMail className="footer-icon" />
                    veezeefarms@gmail.com{" "}
                  </span>
                </li>
                <li className="list">
                  <span>
                    <FaPhoneAlt className="footer-icon" />
                  </span>
                  +234 803 705 13885, +234 812 828 6155{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <p className="copyright">
          {" "}
          &copy; Copy Right {year}. VeeZeePee Farms Limited. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
