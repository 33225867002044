import React from "react";
import PropTypes from "prop-types";
import "./styles/product.css";

interface ProductProps {
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}

export default function Product({ title, description, image, onClick }: ProductProps) : JSX.Element{
  return (
    <div className="product" data-testid="product">
      <img className="product-image" src={image} alt={title} />
      <h3 className="product-title">{title}</h3>
      <p className="product-description">{description}</p>
      <div className="button-section">
        <button className="product-button" onClick={onClick}>
          Explore Produce
        </button>
      </div>
    </div>
  );
}

Product.propTypes = {
  title: PropTypes.string.isRequired,
};
